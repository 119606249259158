<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Grants and Resources
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
              @click="$router.push({name: 'researcher.grants.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher />

            <div class="row d-flex align-items-end">
              <div class="col-md-3">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Begin year</label>
                  <date-picker
                    v-model="filters['begin_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_year')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >End year</label>
                  <date-picker
                    v-model="filters['end_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_year')"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Active</label>
                  <v-select
                    v-model="filters['active']"
                    :options="[
                      { label: 'All', value: '' },
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]"
                    :reduce="item => item.value"
                    @input="saveFilter($event, 'active')"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="mb-1">
                  <button
                    class="btn btn-dark w-100"
                    @click="fetchFilter"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div class="col-md-1">
                <div class="mb-1">
                  <button
                    class="btn btn-dark w-100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Reset filter"
                    @click="reload"
                  >
                    <i data-feather="x" />
                  </button>
                </div>
              </div>
            </div>

            <div
              v-if="data.grants || data.hosts"
              class="row grants__stats"
            > <!-- estos paneles, de momento sólo los ve el admin -->
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Grant money</h5>
                  </div>
                  <div class="card-body">
                    <p class="display-5 mb-0">
                      {{ data.grants.amount.toFixed(2) }} €
                    </p>
                    <p
                      v-if="data.grants.avg == 0"
                      class="text-success"
                    >
                      +0.00% above average
                    </p>
                    <p
                      v-else-if="data.grants.user_avg.avg >= data.grants.avg"
                      class="text-success"
                    >
                      +{{ (((data.grants.user_avg.avg * 100) / data.grants.avg) - 100).toFixed(2) }}% above average
                    </p>
                    <p
                      v-else-if="data.grants.user_avg.avg < data.grants.avg"
                      class="text-danger"
                    >
                      {{ (((data.grants.user_avg.avg * 100) / data.grants.avg) - 100).toFixed(2) }}% below average
                    </p>
                    <hr>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Grant personnel</h5>
                  </div>
                  <div class="card-body">
                    <p class="display-5 mb-0">
                      {{ data.grants.personnels }} people
                    </p>
                    <template v-if="data.grants.personnels_avg_user">
                      <p
                        v-if="data.grants.personnels_avg_user.avg >= data.grants.personnels_avg"
                        class="text-success"
                      >
                        +{{ (((data.grants.personnels_avg_user.avg * 100) / data.grants.personnels_avg) - 100).toFixed(2) }}% above average
                      </p>
                      <p
                        v-if="data.grants.personnels_avg_user.avg < data.grants.personnels_avg"
                        class="text-danger"
                      >
                        {{ (((data.grants.personnels_avg_user.avg * 100) / data.grants.personnels_avg) - 100).toFixed(2) }}% below average
                      </p>
                    </template>
                    <hr>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Host support money</h5>
                  </div>
                  <div class="card-body">
                    <p class="display-5 mb-0">
                      {{ data.hosts.amount.toFixed(2) }} €
                    </p>
                    <p
                      v-if="data.hosts.avg == 0"
                      class="text-success"
                    >
                      +0.00% above average
                    </p>
                    <p
                      v-else-if="data.hosts.user_avg.avg >= data.hosts.avg"
                      class="text-success"
                    >
                      +{{ (((data.hosts.user_avg.avg * 100) / data.hosts.avg) - 100).toFixed(2) }}% above average
                    </p>
                    <p
                      v-else-if="data.hosts.user_avg.avg < data.hosts.avg"
                      class="text-danger"
                    >
                      {{ (((data.hosts.user_avg.avg * 100) / data.hosts.avg) - 100).toFixed(2) }}% below average
                    </p>
                    <hr>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Host support personnel</h5>
                  </div>
                  <div class="card-body">
                    <p class="display-5 mb-0">
                      {{ data.hosts.personnels }} people
                    </p>
                    <template v-if="data.hosts.personnels_avg_user">
                      <p
                        v-if="data.hosts.personnels_avg_user.avg >= data.hosts.personnels_avg"
                        class="text-success"
                      >
                        +{{ (((data.hosts.personnels_avg_user.avg * 100) / data.hosts.personnels_avg) - 100).toFixed(2) }}% above average
                      </p>
                      <p
                        v-if="data.hosts.personnels_avg_user.avg < data.hosts.personnels_avg"
                        class="text-danger"
                      >
                        {{ (((data.hosts.personnels_avg_user.avg * 100) / data.hosts.personnels_avg) - 100).toFixed(2) }}% below average
                      </p>
                    </template>
                    <hr>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Researcher stats</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse>
                <div class="card-content collapse ">
                  <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column pt-0">
                    <div class="header-left">
                      <h4 class="card-title" />
                      <small><a
                        href="#"
                        class="text-primary"
                      ><i data-feather="activity" /> View active</a> <a
                        href="#"
                        class="text-dark ms-2"
                      ><i data-feather="archive" /> View history</a></small>
                    </div>
                    <div class="header-right d-flex align-items-center mt-sm-0 mt-1">
                      <i data-feather="calendar" />
                      <input
                        type="text"
                        class="form-control flat-picker border-0 shadow-none bg-transparent pe-0"
                        placeholder="YYYY-MM-DD"
                      >
                    </div>
                  </div>
                  <div class="card-body">
                    <canvas
                      class="bar-chart-ex chartjs"
                      data-height="400"
                    />
                  </div>
                </div>
              </b-collapse>
            </div> -->

            <!-- start regular grant -->
            <template v-if="data.grants">
              <div
                v-for="grant in data.grants.data"
                :key="grant.id"
                class="card"
              >
                <div class="card-body pb-0 d-flex justify-content-between">
                  <div>
                    <span class="badge bg-light-success text-uppercase">{{ grant.type.type }} grant</span>
                  </div>
                  <div class="text-end">
                    <strong>{{ grant.total_amount ? grant.total_amount : 0 }} € / {{ grant.personnels.length }} personnel</strong> <i
                      data-feather="calendar"
                      class="ms-2"
                    /> {{ grant.begin_date }} -> {{ grant.end_date }}
                    <a
                      class="btn btn-icon btn-primary ms-3"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Go to edit grant"
                      @click="$router.push({name: 'researcher.grants.edit', params: { id: grant.id }})"
                    ><i data-feather="edit-2" /></a>
                  </div>
                </div>
                <div
                  v-b-toggle="`collapseGrant${grant.id}`"
                  class="card-header"
                  @click="loadGrant(grant.id)"
                >
                  <h3 class="cart-title">
                    <a data-action="collapse">{{ grant.title }}</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <b-collapse :visible="selectedGrant == grant.id">
                  <div
                    v-if="!loading"
                    class="card-content"
                  >
                    <div
                      v-if="selectedGrant == grant.id"
                      class="card-body"
                    >
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Institution
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Areas
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Type of funding
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Firm/Funding agency
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Programme
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Tor/Fn
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              People
                            </th>

                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              TA
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              TAGI
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              TAGRU
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.host.name }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.areas[0] ? loadedGrant.areas[0].name : '-' }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.funding_type ? loadedGrant.funding_type.name : '-' }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.funding_agency_manual }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.programme_manual }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.title }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.personnels.length }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.total_amount }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.total_amount_institution }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ loadedGrant.total_amount_group }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    v-else
                    class="loading"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                </b-collapse>
              </div>
            </template>

            <template v-if="data.hosts">
              <div
                v-for="grant in data.hosts.data"
                :key="grant.id"
                class="card"
              >
                <div class="card-body pb-0 d-flex justify-content-between">
                  <div>
                    <span class="badge bg-light-success text-uppercase">{{ grant.type.type }} grant</span>
                  </div>
                  <div class="text-end">
                    <strong>{{ grant.total_amount ? grant.total_amount : 0 }} € / {{ grant.personnels.length }} personnel</strong> <i
                      data-feather="calendar"
                      class="ms-2"
                    /> {{ grant.begin_date }} -> {{ grant.end_date }}
                    <a
                      class="btn btn-icon btn-primary ms-3"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Go to edit grant"
                      @click="$router.push({name: 'researcher.grants.edit', params: { id: grant.id }})"
                    ><i data-feather="edit-2" /></a>
                  </div>
                </div>
                <div
                  v-b-toggle="`collapseGrant${grant.id}`"
                  class="card-header"
                  @click="loadGrant(grant.id)"
                >
                  <h3 class="cart-title">
                    <a
                      data-action="collapse"
                      class="text-capitalize"
                    >{{ grant.title ? grant.title : `${grant.type.type} grant` }}</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <b-collapse :visible="selectedGrant == grant.id">
                  <div
                    v-if="!loading"
                    class="card-content"
                  >
                    <div
                      v-if="selectedGrant == grant.id"
                      class="card-body"
                    >
                      <TypeResource />

                      <!-- ----- Only show if grant have type and it's from the 'Grant & Contract Research' group ----- -->
                      <GrantContractedResearch v-if="loadedGrant.type && loadedGrant.type.parent_type === 1" />

                      <AmountGrant v-if="loadedGrant.type && (loadedGrant.type.type === 'regular' || loadedGrant.type.type === 'in_kind')" />

                      <PeopleFunded v-if="loadedGrant.type && (loadedGrant.type.type === 'regular' || loadedGrant.type.type === 'personal' || loadedGrant.type.type === 'personnel')" />
                      <!-- -----  ----- -->

                      <!-- start monetary host support -->
                      <MonetarySupport v-if="loadedGrant.type && loadedGrant.type.type === 'monetary'" />
                      <!-- end monetary host support -->

                      <IcreaRemarks
                        :visible="true"
                        :item="loadedGrant"
                      />
                      <div class="page-actions">
                        <button
                          v-if="!loading"
                          id="type-success"
                          class="btn btn-success"
                          @click="save"
                        >
                          <i data-feather="save" />
                          <span class="ms-25 align-middle">Update grant</span>
                        </button>
                        <button
                          v-else
                          class="btn btn-outline-success"
                          type="button"
                          disabled=""
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span class="ms-25 align-middle">Saving...</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="loading"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                </b-collapse>
              </div>
            </template>
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import IcreaRemarks from '@/views/back/partials/components/IcreaRemarks.vue'
import DatePicker from 'vue2-datepicker'
import GrantContractedResearch from './components/GrantContractedResearch.vue'
import TypeResource from './components/TypeResource.vue'
import AmountGrant from './components/AmountGrant.vue'
import PeopleFunded from './components/PeopleFunded.vue'
import MonetarySupport from './components/MonetarySupport.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    IcreaRemarks,
    GrantContractedResearch,
    TypeResource,
    AmountGrant,
    PeopleFunded,
    MonetarySupport,
    SearchResearcher,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      selectedGrant: null,
    }
  },
  computed: {
    ...mapGetters({
      data: 'grants/dashboard',
      loadedGrant: 'grants/item',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('typesResource/fetch')
    this.saveFilter(true, 'active')
    this.saveFilter(new Date().getFullYear().toString(), 'begin_year')
    this.saveFilter(new Date().getFullYear().toString(), 'end_year')
    await this.$store.dispatch('grants/filterDashboard', this.filters)
    await this.$store.dispatch('grants/cleanType')
    this.$store.dispatch('status/filterStatus', 'Types of funding')
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async loadGrant(id) {
      if (this.selectedGrant !== id) {
        this.loading = true
        this.selectedGrant = id
        await this.$store.dispatch('grants/fetchId', id)
        this.loading = false
      } else {
        this.selectedGrant = null
      }
    },
    async save() {
      this.loading = true
      try {
        if (this.loadedGrant && this.loadedGrant.id) {
          await this.$store.dispatch('grants/update', { id: this.loadedGrant.id, data: this.loadedGrant })
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.loadedGrant.id) {
        Vue.swal('Grant updated successfully!', '', 'success')
      }

      this.loading = false
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })

      this.$forceUpdate()
    },
    async fetchFilter() {
      await this.$store.dispatch('grants/filterDashboard', this.filters)
    },
    reload() {
      this.$store.dispatch('grants/filterDashboard', {})
    },
  },
}
</script>
